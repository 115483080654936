@media (max-width: 768px) {
  .navbar-toggler {
    border: 0px !important;
    &:focus {
      box-shadow: none !important;
    }
  }
  .general {
    .title {
      p {
        font-size: 20px;
      }
    }
  }

  .cta {
    &.leaf {
      padding: 200px 0px 50px;
      h1 {
        text-align: center;
        font-size: 30px;
      }
      img {
        &.abs {
          position: absolute;
          top: -20px;
          left: -20px;
          width: 65%;
        }
      }
    }
  }

  a {
    &.btn {
      padding: 8px 10px !important;
    }
  }

  .items {
    flex-direction: column;
    padding-bottom: 20px;
    .item {
      width: 100%;
      padding-bottom: 10px;
      p {
        text-align: justify !important;
      }
    }
    &:first-child {
      .item {
        &:first-child {
          text-align: left;
          padding-right: 0px;
        }
      }
    }
    &:nth-child(2) {
      .item {
        &:first-child {
          order: unset;
          padding-left: 0px;
        }
      }
    }
    &:last-child {
      .item {
        &:first-child {
          padding-right: 0px;
          text-align: left;
        }
      }
    }
  }

  .cta-ba {
    .overlay {
      padding: 30px 0px;
      h1 {
        font-size: 22px;
      }
    }
  }

  h3 {
    font-size: 22px !important;
  }

  .footer {
    p {
      &.icons {
        padding-bottom: 20px;
      }
    }
    .copyright {
      p {
        &.privacy {
          text-align: center;
        }
      }
    }
  }

  .servicescard {
    padding: 20px 0px;
    &:first-child {
      border: 0px !important;
    }
    &:nth-child(2) {
      border: 0px !important;
    }
    &:nth-child(3) {
      border: 0px !important;
    }
  }

  .pb-mob {
    padding-bottom: 30px;
  }

  ul {
    &.contact {
      li {
        a {
          span {
            font-size: 15px;
          }
        }
      }
    }
  }

  iframe {
    width: 100%;
    padding-bottom: 30px;
  }

  .contact {
    flex-direction: column-reverse;
  }
}
