.justify-content-right {
  justify-content: right;
}

body {
  font-family: "Raleway", sans-serif !important;
}

.navbar {
  background-color: #fff !important;
  a {
    &.nav-link {
      font-size: 14px !important;
      font-weight: 500;
      &.active {
        color: #dc9541 !important;
      }
      &.cta-btn {
        font-weight: bold;
        color: #103f38;
        text-decoration: underline;
      }
    }
  }
}

.ptb-5 {
  padding: 4rem 0px;
}
.hero {
  background-image: url(../img/hero.png);
  min-height: 90vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff;
  background-attachment: fixed;
  .hero-cont {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 90vh;
    flex-direction: column;
    background-color: #00000040;
    p {
      font-size: 24px;
      font-weight: 300;
      span {
        font-weight: bold;
      }
    }
    h1 {
      font-size: 42px;
      margin-bottom: 16px;
      font-weight: 300;
      span {
        font-weight: bold;
        color: #dc9541;
      }
    }
  }
}

.breadcrumb {
  margin-bottom: 0px !important;
  background-image: url(../img/breadcrumb.png);
  background-repeat: no-repeat;
  background-size: cover;
  h3,
  small {
    color: #fff;
  }
  h3 {
    margin-bottom: 2px;
  }
}

.cta-green {
  background-image: url(../img/cta-green.png);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff;
  background-attachment: fixed;
}

.cta-ba {
  background-image: url(../img/cta.png);
  min-height: 40vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #fff;
  background-attachment: fixed;
  .overlay {
    background-color: #00000082;
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    h1 {
      font-size: 30px;
      font-weight: 400;
      width: 80%;
      margin: auto;
      padding-bottom: 20px;
      span {
        color: #dc9541;
        font-weight: bold;
      }
    }
  }
}

a {
  &.btn {
    border: 0 !important;
    padding: 8px 40px !important;
    margin-top: 10px;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #fff !important;
    &.primary {
      background: #dc9541 !important;
      &:hover {
        background-color: #103f38 !important;
        transition: all 0.6s ease;
      }
    }
    &.secondary {
      background: #1b6b5f;
      &:hover {
        background-color: #dc9541;
        transition: 0.6s ease all;
      }
    }
    &.green {
      background: #103f38;
      &:hover {
        background-color: #dc9541;
        transition: 0.6s ease all;
      }
    }
  }
}

.text-green {
  color: #103f38 !important;
}

img {
  &.drss-home {
    position: relative;
    top: 50px;
  }
}

.cta {
  &.leaf {
    background: #103f38;
    padding: 120px 0px;
    position: relative;
    img {
      &.abs {
        position: absolute;
        top: -100px;
        left: -80px;
        width: 55%;
        transform: rotate(336deg);
      }
    }
    h1 {
      font-size: 50px;
      /* line-height: 60px; */
      color: #fff;
      font-weight: 300;
    }
  }
}

.items {
  display: flex;
  align-items: center;
  .item {
    display: inline-block;
    width: 50%;
    h3 {
      color: #103f38;
      font-weight: bold;
    }
    p {
      margin-bottom: 0px;
    }
    img {
      width: 100%;
    }
  }
  &:first-child {
    .item {
      &:first-child {
        text-align: right;
        padding-right: 30px;
      }
    }
  }
  &:nth-child(2) {
    .item {
      &:first-child {
        order: 2;
        text-align: left;
        padding-left: 30px;
      }
    }
  }
  &:last-child {
    .item {
      &:first-child {
        text-align: right;
        padding-right: 30px;
      }
    }
  }
}

.general {
  .title {
    p {
      color: #103f38;
      font-size: 24px;
      margin-bottom: 0px;
      line-height: 30px;
      span {
        font-weight: bold;
      }
    }
    padding-bottom: 15px;
  }
}

.btn-round {
  height: 30px;
  width: 30px;
  border-radius: 50px;
  line-height: 28px;
  &.green {
    background-color: #103f38;
    svg {
      color: #fff;
    }
  }
}

.fw-600 {
  font-weight: 600;
}

ul {
  &.reach {
    li {
      padding-bottom: 10px;
      a {
        position: relative;
        display: flex;
        align-items: center;
        svg {
          position: absolute;
          left: 0;
        }
        span {
          margin-left: 20px;
        }
      }
    }
  }
  &.contact {
    li {
      padding-bottom: 15px;
      a {
        svg {
          color: #dc9541;
          font-size: 24px;
          margin-right: 15px;
          position: absolute;
        }
        span {
          font-size: 18px;
          position: relative;
          width: 80%;
          left: 40px;
        }
      }
    }
  }
  li {
    a {
      color: #333;
      text-decoration: none;
      font-size: 14px;
      svg {
        color: #dc9541;
      }
    }
  }
}

.footer {
  background: url("../img/footerbg.png");
  background-size: cover;
  background-position: 50% -141%;
  background-repeat: no-repeat;
  p {
    font-size: 14px;
    text-align: justify;
    margin: 0;
    &.icons {
      a {
        color: #103f38;
        font-size: 20px;
        padding-right: 7px;
      }
    }
  }
  h6 {
    font-weight: 700;
    color: #103f38;
  }

  .copyright {
    background-color: #103f38 !important;
    color: #fff;
    p {
      font-size: 12px;
      text-align: center;
      margin-bottom: 0;
      a {
        color: #fff !important;
      }
      &.privacy {
        text-align: right;
        a {
          padding-left: 10px;
        }
      }
    }
  }
}

.sidebar-nav {
  background: #103f38 !important;
  color: #fff;
  a {
    text-decoration: none;
    color: #fff;
    h6 {
      font-size: 20px;
      padding-top: 10px;
    }
    img {
      width: 100%;
      height: 100px;
      object-fit: cover;
      border-radius: 10px;
    }
  }
}

.servicescard {
  padding: 3rem 0;
  &:first-child {
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
  }
  &:nth-child(2) {
    border-bottom: 1px solid #ddd;
  }
  &:nth-child(3) {
    border-right: 1px solid #ddd;
  }
}
